import { ReactElement, useState } from 'react';

import { IFullList } from '@/common/service/api/Activity/Activity.domain';
import { ActivitiesList } from '@/screens/AttractionsPage/ui/ActivitiesList';

import { FeaturedActivities } from '@/shared/FeaturedActivities/ui/FeaturedActivities';
import * as S from './SoldOutPage.styles';
import { IDestinationList } from '@/entities/Destination/domain/Destination.domain';
import { Container } from '@/common/ui/containers/Container';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { useDateContext } from '@/common/app/contexts';

type SoldOutProps = {
    currentDestination: IDestinationList;
    title: string;
    fullList: IFullList;
    recommends?: IRecommendsItem[];
    isCategoryPage?: boolean;
    item_list_name: string;
    item_list_id: string;
};

const SoldOutPage = ({
    currentDestination,
    fullList,
    recommends,
    isCategoryPage,
    title,
    item_list_name,
    item_list_id,
}: SoldOutProps): ReactElement => {
    const [isHideOn, setHideOn] = useState(false);
    const { date } = useDateContext();
    const from = new Date(date.from).toLocaleString('default', {
        day: 'numeric',
        month: 'short',
    });
    const to = new Date(date.to).toLocaleString('default', {
        day: 'numeric',
        month: 'short',
    });

    return (
        <>
            <Container>
                <S.Title data-test-id="soldout-title">{title}</S.Title>
                <S.SoldoutBox>
                    <S.SoldOutInfo>
                        <S.SoldoutDisclaimer data-test-id="soldout-disclaimer-dates">
                            {`Activities from ${from} - ${to}`}
                            <br /> are Sold Out
                        </S.SoldoutDisclaimer>
                        <S.IconContainer>
                            <S.IconCart data-test-id="soldout-icon" />
                        </S.IconContainer>
                        <S.SoldoutDisclaimer data-test-id="soldout-disclaimer-text">
                            Try changing your dates
                            {isCategoryPage && <> or check out some great experiences below! </>}
                        </S.SoldoutDisclaimer>
                    </S.SoldOutInfo>
                </S.SoldoutBox>
                <S.ToggleButton
                    onClick={() => setHideOn(!isHideOn)}
                    data-test-id="soldout-toggle-button"
                >
                    <S.Toggle toggleOn={isHideOn} data-test-id="soldout-toggle-text">
                        <S.ToggleIcon />
                    </S.Toggle>
                    <S.ToggleText>Hide Sold Out Trips</S.ToggleText>
                </S.ToggleButton>{' '}
            </Container>{' '}
            {!isHideOn && (
                <ActivitiesList
                    data-test-id="soldout-activities-list"
                    title=""
                    fullList={fullList}
                    hideSort
                    noBlock
                    listName={item_list_name}
                    listId={item_list_id}
                    availableAttributes={[]}
                />
            )}
            {Boolean(recommends?.length) && (
                <>
                    <Container>
                        <S.SoldoutFullDisclaimer data-test-id="soldout-dont-worry">
                            Don&apos;t worry, find more great experiences below
                        </S.SoldoutFullDisclaimer>
                    </Container>
                    <FeaturedActivities
                        activities={recommends ?? []}
                        title={`${currentDestination.name} Experiences - Available ${from} - ${to}`}
                        maxWidthForTitle={'600px'}
                        item_list_id={item_list_id}
                        item_list_name={item_list_name}
                    />
                </>
            )}
        </>
    );
};

export { SoldOutPage };
export default SoldOutPage;
