import emptyattraction_desktop from '@/shared/images/Images/emptyattraction_desktop.png';
import emptyattraction_desktop_dark from '@/shared/images/Images/emptyattraction_desktop_dark.png';
import emptyattraction_ipad from '@/shared/images/Images/emptyattraction_ipad.png';
import emptyattraction_ipad_dark from '@/shared/images/Images/emptyattraction_ipad_dark.png';
import emptyattraction_mobile from '@/shared/images/Images/emptyattraction_mobile.png';
import emptyattraction_mobile_dark from '@/shared/images/Images/emptyattraction_mobile_dark.png';
import { CartIcon } from '@/shared/images/icons/CartIcon';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import styled from 'styled-components';

export const Container = styled.div`
    width: 90%;
    margin: 10px auto;

    ${Screen.desktop} {
        width: 1124px;
        padding: 30px;
        margin: 0 auto;
    }
`;

export const SoldOutContainer = styled.div`
    ${Screen.desktop} {
        max-width: 1064px;
        margin: 0 auto;
    }
`;

export const Title = styled.h2`
    ${Text.Full.heading2};
    color: ${cssVars.fg_gray1_white};
`;
export const ImageContainer = styled.div<{ isLight: boolean }>`
    background-image: ${({ isLight }) =>
        isLight ? `url(${emptyattraction_mobile.src})` : `url(${emptyattraction_mobile_dark.src})`};
    width: 100%;
    height: 217px;
    margin: 50px auto 55px;

    ${Screen.ipad} {
        width: 578px;
        height: 417px;
        margin: 80px auto 90px;
        background-image: ${({ isLight }) =>
            isLight ? `url(${emptyattraction_ipad.src})` : `url(${emptyattraction_ipad_dark.src})`};
    }
    ${Screen.desktop} {
        width: 646px;
        height: 466px;
        margin: 100px auto;
        background-image: ${({ isLight }) =>
            isLight
                ? `url(${emptyattraction_desktop.src})`
                : `url(${emptyattraction_desktop_dark.src})`};
    }
`;

export const SoldOutMessageContainer = styled.div`
    border-radius: 15px;
    background-color: ${cssVars.bg_ash_dark2};
    text-align: center;
    margin: 0 0 20px 0;
    padding: 15px;
`;

export const SoldOutMessage = styled.div`
    ${Text.Mobile.caption.medium};
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.desktop} {
        ${Text.Desktop.large.medium};
    }
`;

export const SoldOutMessageExperience = styled.div`
    color: ${Palette.green.normal};
    ${Text.Mobile.caption.medium};
    ${Screen.desktop} {
        ${Text.Desktop.large.medium};
    }
`;

export const AvailableExperiences = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.heading2};
    ${Screen.desktop} {
        ${Text.Desktop.heading2};
    }
`;

export const SoldoutBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    ${Screen.desktop} {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
`;

export const SoldoutDisclaimer = styled.div`
    display: flex;
    align-items: center;
    color: ${cssVars.disclaimer_red};
    ${Text.Mobile.heading4.bold};
    &:first-of-type {
        margin-bottom: 8px;
    }
    order: -1;

    br {
        display: none;
    }

    ${Screen.desktop} {
        ${Text.Desktop.heading3};
        padding: 15px 0;
        width: 35%;
        padding: 15px 0;
        order: inherit;
        br {
            display: block;
        }
        &:first-of-type {
            justify-content: flex-end;
            text-align: right;
            margin-bottom: 0;
        }
    }
`;

export const SoldoutFullDisclaimer = styled.div`
    color: ${cssVars.disclaimer_red};
    ${Text.Mobile.heading4.bold};
    ${Screen.desktop} {
        ${Text.Desktop.heading3};
        width: 100%;
    }
`;

export const IconCart = styled(CartIcon)`
    ${Screen.ipad} {
        width: 100px;
        height: 100px;
    }

    ${Screen.desktop} {
        width: 85px;
        height: 85px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: none;
    margin-top: 16px;
    ${Screen.desktop} {
        margin-top: 0;
        width: auto;
    }
`;

export const ToggleButton = styled.div`
    display: flex;
    align-items: center;
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.heading4.regular};
    cursor: pointer;
    user-select: none;
    padding: 20px 0;
    margin-bottom: 30px;

    ${Screen.desktop} {
        padding: 30px 0;
    }
`;

export const ToggleText = styled.div`
    ${Text.Mobile.heading4.bold};
    color: ${cssVars.disclaimer_red};
    margin-left: 10px;
`;

export const Toggle = styled.div<{ toggleOn: boolean }>`
    background-color: ${({ toggleOn }) => (toggleOn ? Palette.green.normal : Palette.gray.gray4)};
    height: 26px;
    width: 48px;
    border-radius: 40px;
    display: flex;
    justify-content: ${({ toggleOn }) => (toggleOn ? 'flex-end' : 'flex-start')};
`;

export const ToggleIcon = styled.div`
    width: 22px;
    height: 22px;
    background-color: ${Palette.white.normal};
    border-radius: 100%;
    margin: 2px;
    padding: 5px;
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    ${Screen.ipad} {
        grid-gap: 30px;
    }
    ${Screen.desktop} {
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr;
        margin: 10px 0;
    }
`;

export const SoldOutInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    ${Screen.desktop} {
        flex-direction: row;
        gap: 48px;
    }
`;
