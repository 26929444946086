import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { Transition } from '@/styles/Themes/KeyFrames';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';

export const Title = styled.h1`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2}
    margin: 0 0 10px;
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 30px 0 10px;
    }
`;

export const AttractionDescription = styled.div<{ readMore: boolean }>`
    ${Text.Full.large.regular};
    margin: 10px 0 0;
    color: ${cssVars.fg_gray1_white};
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    ${({ readMore }) => !readMore && '-webkit-line-clamp: 3;'}
    -webkit-box-orient: vertical;

    ${Screen.desktop} {
        -webkit-box-orient: unset;
        margin: 10px auto 40px;
    }
`;

export const ReadMore = styled.span<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 36px;
    ${Text.Full.large.medium}
    color: ${Palette.green.normal};
    ${Transition.normal};
    margin-bottom: 12px;
    cursor: pointer;

    ${Screen.desktop} {
        display: none;
    }
`;

export const Arrow = styled(DropdownArrow)<{ isActive: boolean }>`
    margin-left: 6px;
    fill: ${Palette.green.normal};
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    ${Transition.normal};
`;
