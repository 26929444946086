export const CartIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`CartIcon ${props.className}`}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--svg-ash-dark2)" />
            <path
                d="M43.15 41.45H24.79C24.0502 41.4482 23.3332 41.1933 22.7583 40.7275C22.1834 40.2618 21.7853 39.6134 21.63 38.89L17.85 21C17.7231 20.4338 17.4075 19.9275 16.9551 19.5641C16.5027 19.2007 15.9403 19.0018 15.36 19H11.5C11.3674 19 11.2402 18.9473 11.1464 18.8536C11.0527 18.7598 11 18.6326 11 18.5C11 18.3674 11.0527 18.2402 11.1464 18.1464C11.2402 18.0527 11.3674 18 11.5 18H15.36C16.1709 18.0027 16.9565 18.2831 17.586 18.7943C18.2155 19.3055 18.651 20.0169 18.82 20.81L19.5 24H48.15C48.6021 24.0086 49.0464 24.1193 49.4497 24.3239C49.8531 24.5284 50.2049 24.8215 50.479 25.1811C50.7531 25.5408 50.9423 25.9578 51.0325 26.4009C51.1227 26.844 51.1116 27.3018 51 27.74L48.33 37.57C47.9945 38.6854 47.3105 39.6639 46.3783 40.3622C45.4461 41.0604 44.3147 41.4417 43.15 41.45ZM19.71 25L22.61 38.67C22.7172 39.1693 22.9917 39.6171 23.3882 39.939C23.7847 40.2609 24.2793 40.4377 24.79 40.44H43.15C44.0984 40.4329 45.0198 40.1237 45.7805 39.5572C46.5412 38.9908 47.1015 38.1966 47.38 37.29L50 27.48C50.0713 27.1927 50.078 26.8932 50.0196 26.6031C49.9612 26.3129 49.839 26.0393 49.6621 25.8021C49.4851 25.5648 49.2576 25.3698 48.9962 25.2311C48.7347 25.0924 48.4457 25.0135 48.15 25H19.71Z"
                fill="var(--svg-gray2-gray3)"
            />
            <path
                d="M26.7895 50.9999C26.147 51.0058 25.5173 50.8206 24.9803 50.4678C24.4434 50.115 24.0234 49.6105 23.7739 49.0184C23.5243 48.4263 23.4565 47.7734 23.5789 47.1427C23.7013 46.512 24.0085 45.9319 24.4614 45.4761C24.9143 45.0204 25.4925 44.7096 26.1225 44.5833C26.7524 44.4569 27.4057 44.5208 27.9993 44.7666C28.5929 45.0125 29.1 45.4293 29.4562 45.964C29.8124 46.4988 30.0015 47.1274 29.9995 47.7699C29.9969 48.6223 29.6585 49.4393 29.0577 50.0439C28.4568 50.6485 27.6419 50.992 26.7895 50.9999ZM26.7895 45.5299C26.3453 45.5239 25.9093 45.6502 25.537 45.8927C25.1647 46.1352 24.8729 46.4829 24.6988 46.8917C24.5246 47.3004 24.476 47.7517 24.559 48.1882C24.642 48.6247 24.8529 49.0266 25.165 49.3429C25.477 49.6592 25.876 49.8755 26.3114 49.9644C26.7467 50.0533 27.1986 50.0107 27.6097 49.8421C28.0207 49.6735 28.3723 49.3864 28.6198 49.0174C28.8673 48.6484 28.9995 48.2142 28.9995 47.7699C28.9996 47.181 28.7677 46.6157 28.3541 46.1965C27.9405 45.7772 27.3784 45.5378 26.7895 45.5299Z"
                fill="var(--svg-gray2-gray3)"
            />
            <path
                d="M42.3203 51C41.6786 51.002 41.0508 50.8134 40.5165 50.4581C39.9822 50.1029 39.5653 49.597 39.3189 49.0045C39.0724 48.4121 39.0074 47.7598 39.1321 47.1304C39.2568 46.5009 39.5656 45.9227 40.0193 45.469C40.473 45.0153 41.0512 44.7065 41.6807 44.5818C42.3101 44.4571 42.9624 44.5221 43.5548 44.7686C44.1473 45.015 44.6532 45.4319 45.0084 45.9662C45.3637 46.5006 45.5523 47.1283 45.5503 47.77C45.5503 48.6267 45.21 49.4482 44.6043 50.054C43.9985 50.6597 43.1769 51 42.3203 51ZM42.3203 45.53C41.8768 45.528 41.4428 45.6577 41.0731 45.9026C40.7034 46.1476 40.4148 46.4967 40.2437 46.9058C40.0726 47.315 40.0268 47.7657 40.1121 48.2008C40.1974 48.636 40.41 49.0361 40.7228 49.3504C41.0357 49.6646 41.4348 49.879 41.8696 49.9662C42.3044 50.0534 42.7553 50.0097 43.1652 49.8404C43.5751 49.6712 43.9255 49.3841 44.1721 49.0155C44.4187 48.6469 44.5503 48.2135 44.5503 47.77C44.5503 47.1776 44.3157 46.6094 43.8978 46.1896C43.4798 45.7698 42.9126 45.5327 42.3203 45.53Z"
                fill="var(--svg-gray2-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.004 37.0978C27.8906 36.8948 27.9632 36.6384 28.1661 36.525C29.355 35.8608 31.519 34.7163 35.0189 34.7163C38.2795 34.7163 40.1704 35.7879 41.3261 36.5396C41.521 36.6664 41.5762 36.9271 41.4495 37.122C41.3227 37.3169 41.062 37.3721 40.8671 37.2453C39.7936 36.5471 38.0619 35.5582 35.0189 35.5582C31.7258 35.5582 29.71 36.6268 28.5767 37.2599C28.3738 37.3733 28.1173 37.3007 28.004 37.0978Z"
                fill="#0DD1C5"
            />
            <path
                d="M38.8138 30.1369C38.8138 30.5089 38.5123 30.8104 38.1403 30.8104C37.7683 30.8104 37.4668 30.5089 37.4668 30.1369C37.4668 29.7649 37.7683 29.4634 38.1403 29.4634C38.5123 29.4634 38.8138 29.7649 38.8138 30.1369Z"
                fill="#0DD1C5"
            />
            <path
                d="M32.3411 30.1369C32.3411 30.5089 32.0396 30.8104 31.6676 30.8104C31.2957 30.8104 30.9941 30.5089 30.9941 30.1369C30.9941 29.7649 31.2957 29.4634 31.6676 29.4634C32.0396 29.4634 32.3411 29.7649 32.3411 30.1369Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
